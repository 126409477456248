<template>
  <div class="login">
    <el-row>
      <el-col :span="24">
        <div class="blank"></div>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="8">
        <el-card shadow="always" class="box-card" style="background-color:#CCEFE0;">
          <div style="text-align: center">
            <!-- <img width="390px" height="225px" alt="" src="https://www.tcpcat.com/r/ajc/menglijiaopc.png"> -->
            <h1>艾智能数据管理系统</h1>
          </div>

          <!-- 表单 -->
          <el-form :model="numberValidateForm" ref="numberValidateForm" label-width="80px" class="demo-ruleForm">
            <el-form-item label="用户" prop="username" :rules="[
              { required: true, message: '用户名不能为空'}
            ]">
              <el-input @keyup.enter.native="submitForm('numberValidateForm')" placeholder="请输入用户名"
                        type="username" v-model.number="numberValidateForm.username" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" :rules="[
              { required: true, message: '密码不能为空'}
            ]">
              <el-input @keyup.enter.native="submitForm('numberValidateForm')" placeholder="请输入密码"
                        v-model="numberValidateForm.password" show-password></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button @click="OnRegisterClick" style="margin-right: 60px">注册</el-button>
              <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading"
                         @click="submitForm('numberValidateForm')">登陆
              </el-button>
            </el-form-item>
          </el-form>
          <!-- 修改密码 Start -->
          <el-dialog title="注册账号" :visible.sync="RegisterDialog" width="600px">
            <el-form>
              <el-form-item label="用户名：" label-width="100px">
                <el-input v-model="InputUsername" autocomplete="off" maxlength="16" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="登录密码：" label-width="100px">
                <el-input v-model="InputPassword1" show-password autocomplete="off" maxlength="32"></el-input>
              </el-form-item>
              <el-form-item label="确认密码：" label-width="100px">
                <el-input v-model="InputPassword2" show-password autocomplete="off" maxlength="32"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="RegisterDialog = false">取 消</el-button>
              <el-button type="primary" @click="RegisterCommit">注 册</el-button>
            </div>
          </el-dialog>
          <!-- 修改密码 END -->
          <!-- <div style="width:70px;display:block;margin:0 auto"> <el-checkbox v-model="multiple">多处登录</el-checkbox></div> -->
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  data() {
    return {
      multiple: false,
      fullscreenLoading: false,
      numberValidateForm: {
        username: '',
        password: '',
      },
      //注册
      RegisterDialog: false,
      RegCid: '',
      InputUsername: '',
      InputPassword1: "",
      InputPassword2: "",
    }
  },
  created() {
    let query = this.$route.query
    console.log(query)
    if (query.u) {
      this.username = query.u
      this.password = query.p
    } else {
      this.username = ''
      this.password = ''
    }
  },
  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await this.$api.login({
            username: "" + this.numberValidateForm.username,
            password: "" + this.numberValidateForm.password,
          })
          console.log(res)
          if (res.status == 1) {
            console.log("login:", res.data)
            this.$notify({
              title: '登陆成功',
              message: '欢迎使用艾灸床管理系统',
              type: 'success',
            })
            this.fullscreenLoading = true

            var info = res.data
            this.$store.dispatch('Login', info)

            // 延时，加钱可以优化
            setTimeout(() => {
              this.fullscreenLoading = false
              this.$router.push({
                name: 'home',
              })
            }, 990)

          } else {
            this.$alert('用户或者密码不正确', '登陆提示', {
              confirmButtonText: '确定'
            })
          }

        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    OnRegisterClick(){
      this.$notify({
        title:'提示',
        message: '暂未开启外部注册功能，请联系管理员在系统内添加用户',
        type: 'warning',
      })
      // RegisterDialog = true
    },
    async RegisterCommit() {
      // TODO 暂时写死
      this.RegCid = "203458617557389312"

      if (this.InputPassword1 === this.InputPassword2) {
        let res = await this.$api.register({
          cid: this.RegCid,
          username: "" + this.InputUsername,
          password: "" + this.InputPassword1,
        })
        console.log(res)
        if (res.status == 1) {
          this.$message({
            message: '注册成功',
            type: 'success',
          })
          this.RegisterDialog = false
          this.numberValidateForm.username = this.InputUsername
          this.numberValidateForm.password = this.InputPassword1
        }
      } else {
        this.$notify({
          title: '错误',
          message: "两次密码输入不一致",
          type: 'error',
        })
      }
    },
  },
}
</script>

<style scoped>
.blank {
  margin-bottom: 140px;
}

.el-form {
  margin-right: 50px;
}
</style>
